<template>
  <div class="page">
    <el-form :model="formInfo" label-width="120px" :rules="formRules" ref="formInfo">
      <el-card>
        <template slot="header">
          <span class="title">卡券信息</span>
          <div class="button-back">
            <el-button @click="() => {
              $router.go(-1);
            }
              " size="small" class="back_btn">
              <img src="@/assets/back.png" alt="" />
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-form-item label="关联销售单号" prop="trade_sn" :error="noMatchError">
            <el-input v-model.trim="formInfo.trade_sn" size="small" style="width: 200px" placeholder="请输入关联销售单号"
              @blur="handleSellerSnBlur" :disabled="isEdit" />
          </el-form-item>

          <el-form-item label="应用类型" prop="app_type_shop_type_flag">
            <el-select size="small" placeholder=" 请选择应用类型" v-model="formInfo.app_type_shop_type_flag" filterable clearable
              style="width: 200px" disabled>
              <el-option v-for="item in filterdouble" :key="item.app_type_shop_type_flag" :value="item.value"
                :label="item.label" />
            </el-select>
          </el-form-item>

          <el-form-item label="录入卡号">
            <div class="card-exp-area">
              <el-form-item label="所属商城">
                <el-tooltip :content="formInfo.shop_name" :disabled="(formInfo.shop_name || '').length < 12"
                  placement="top" effect="light">
                  <el-select v-model="formInfo.shop_id" filterable size="small" style="width: 200px" placeholder="请选择商城"
                    clearable @change="handleShopChange" @focus="handleShopFocus">
                    <el-option v-for="(item, index) in shopList" :label="item.shop_name" :value="item.shop_id"
                      :key="index" />
                  </el-select>
                </el-tooltip>
              </el-form-item>

              <!-- 卡号上传 -->
              <card-upload :shopId="formInfo.shop_id" :appType="formInfo.app_type_shop_type_flag"
                :cardData="formInfo.cardList" @submit="exportCard" />
              <!-- 卡号上传 -->
            </div>
          </el-form-item>

          <!-- 卡券信息 -->
          <card-list ref="card_list" @change="handleChangeCardList" @range="changeCardRange" :tradeSn="formInfo.trade_sn"
            :shopData="shopList" :cardData="formInfo.cardList" :filterColumn="filterColumn" :disabled="disabledColumn"
            :appType="formInfo.app_type_shop_type_flag" />
          <!-- 卡券信息 -->

          <el-row style="width: 55%">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input-number size="small" :precision="2" style="width: 200px" v-model="formInfo.total_price"
                  disabled></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input-number size="small" :precision="2" style="width: 200px" v-model="formInfo.after_discount_price"
                  disabled></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="no-hidden">
        <template slot="header">
          <span class="title">基础信息</span>
        </template>
        <div class="base-info">
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="客户名称" prop="client_name">
                <el-autocomplete style="width: 100%" :fetch-suggestions="queryClientSearchAsync" size="small"
                  v-model.trim="formInfo.client_name" clearable disabled></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理名称">
                <el-autocomplete style="width: 100%" placeholder="请输入代理人名称" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('AGENCY')" :fetch-suggestions="agentScreen" size="small"
                  v-model.trim="formInfo.agent_name" clearable></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人名称">
                <el-autocomplete style="width: 100%" :trigger-on-focus="false" placeholder="请输入联系人名称" :disabled="true"
                  @focus="returnMatchSuggestion('LINKMAN')" :fetch-suggestions="linkNameScreen" size="small"
                  v-model.trim="formInfo.link_name" clearable />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="联系电话" :error="phoneError">
                <el-autocomplete style="width: 100%" placeholder="请输入联系电话" :trigger-on-focus="false" :disabled="true"
                  @focus="returnMatchSuggestion('PHONE')" :fetch-suggestions="phoneScreen" size="small"
                  @change="handlePhoneChange" @select="phoneSelect" v-model.trim="formInfo.link_phone" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售人员" prop="sell_name">
                <el-input size="small" v-model="formInfo.sell_name" :disabled="true" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="制单人名称">
                <el-autocomplete style="width: 100%" placeholder="请输入制单人名称" :disabled="true" :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('WRIGHT')" :fetch-suggestions="makeOrderScreen" size="small"
                  v-model.trim="formInfo.make_order_name" clearable />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址">
            <el-autocomplete style="width: 100%" :trigger-on-focus="false" :disabled="true"
              @focus="returnMatchSuggestion('ADDRESS')" :fetch-suggestions="addressScreen" size="small"
              placeholder="请输入客户地址" v-model.trim="formInfo.client_addr" :maxlength="255" clearable />
          </el-form-item>
          <el-form-item label="备注信息">
            <el-input size="small" type="textarea" :maxlength="200" placeholder="请输入200字以内的备注信息"
              v-model.trim="formInfo.remark" rows="5" resize="none" />
          </el-form-item>
          <el-form-item align="center" style="width: 100%">
            <el-button type="primary" @click="isPermission" :disabled="saveDisabled">保存</el-button>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from "@/api/saleCard";
import mixins from "./saleOrderRefundMixins/index";
import * as API_Order from "@/api/order";
import { asyncRouterMap } from "@/router";

import cardList from "@/views/tools/sale-card-open/components/cardList";
import cardUpload from "@/views/tools/sale-card-open/components/cardUpload";

export default {
  name: "addSaleRefundRecord",
  mixins: [mixins],
  components: {
    cardList,
    cardUpload,
  },
  computed: {
    filterdouble () {
      let cardTypes = this.cardTypes;
      if (!this.double_choice_mall_open) {
        cardTypes = this.cardTypes.filter(item => item.value !== 2)
      }
      return cardTypes;
    }
  },
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      // K20220105000001
      filterColumn: ["统一折扣率设置"],
      disabledColumn: [
        "面值/价位",
        "折扣率(%)",
        "优惠金额",
        "优惠后金额",
        "统一折扣率设置",
      ],
      noMatchError: "",
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      originalData: [],
      shop_card_info_list: [],
      formInfo: {
        app_type_shop_type_flag: "",
        trade_sn: "", // K20230420000001
        shop_id: "",
        shop_name: "",
        total_price: "",
        after_discount_price: "",
        client_name: "",
        client_addr: "",
        sell_name: "",
        link_name: "",
        link_phone: "",
        agent_name: "",
        remark: "",
        make_order_name: "",
        cardList: [],
        enterprise_id: "",
        enterprise_name: "",
      },
      formRules: {
        app_type_shop_type_flag: [
          {
            required: true,
            message: "请选择应用类型",
            trigger: ["blur", "change"],
          },
        ],
        trade_sn: [
          {
            required: true,
            message: "请输入关联销售单号",
            trigger: ["blur", "change"],
          },
        ],
        // shop_id: [
        //   { required: true, message: "请选择所属商城", trigger: "blur" },
        // ],
        client_name: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: ["blur", "change"],
          },
        ],
        sell_name: [
          {
            required: true,
            message: "请输入销售人员名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      textarea: "",
      shopCardList: [],
      phoneError: "",
      saveDisabled: false,
      originalShopId: "",
      errorShopId: "",
    };
  },
  mounted () {
    API_Order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.checkPermission();
    // this.getClientList();
    this.getDataInfo();
  },
  methods: {
    getDataInfo () {
      if (!this.isEdit) return;
      API_saleCard.refundSellNewInfo(this.id).then((res) => {
        const {
          app_type_shop_type_flag,
          client_name,
          agent_name,
          link_name,
          link_phone,
          client_addr,
          sell_name,
          make_order_name,
          total_price,
          after_discount_price,
          remark,
          shop_card_info_list,
          sell_trade_sn,
          enterprise_id,
          enterprise_name,
        } = res;
        this.shop_card_info_list = [...shop_card_info_list];

        const cardList = [...shop_card_info_list]
          .map((shop) => {
            let list = [];
            shop.card_range_list.forEach((item) => {
              list.push({
                ...item,
              });
            });
            return [...list];
          })
          .flat();
        // console.log(res,cardList,this.shop_card_info_list);
        this.formInfo = {
          trade_sn: sell_trade_sn,
          app_type_shop_type_flag,
          client_name,
          agent_name,
          link_name,
          link_phone,
          client_addr,
          sell_name,
          make_order_name,
          total_price,
          after_discount_price,
          remark,
          cardList,
          enterprise_id,
          enterprise_name,
        };
        this.getShopList(app_type_shop_type_flag, this.shop_card_info_list);
      });
    },
    exportCard (params) {
      let shop_card_info_list = this.$refs.card_list.getShopCardInfoList();
      let cards = this.$refs.card_list.getCardData();
      if (!this.checkCardSave(cards)) {
        return this.$message.error("请完善卡券信息");
      }
      API_saleCard.refundAutoIdentify({
        ...params,
        shop_card_info_list,
        sell_trade_sn: this.formInfo.trade_sn,
      }).then((res) => {
        if (!this.checkCardExportRules(res)) return;
        const { shop_card_info_list, shop_id, shop_name, app_type_shop_type_flag } = res;
        this.shop_card_info_list = shop_card_info_list;
        let cardList = this.shop_card_info_list
          .map((shop) => {
            let list = [];
            shop.card_range_list.forEach((item) => {
              list.push({
                ...item,
                // shop_id,
                // shop_name,
                app_type_shop_type_flag,
                unify_discount_rate_status: !!item.unify_discount_rate_status,
              });
            });
            return [...list];
          })
          .flat();
        this.formInfo.cardList = cardList;
      });
    },
    setRangeCardList ({ item, index, cards }, res) {
      const { shop_id, shop_name } = item;
      cards[shop_id] = res.card_range_list;
      this.shop_card_info_list = [];
      for (const [key, value] of Object.entries(cards)) {
        if (key != "") {
          let _value = [...value];
          // const { card_code_start, card_code_end, card_num } = _value[index];
          let sitem = value.find((v) => v.shop_id == key);
          if (key == shop_id) {
            _value[index] = {
              ..._value[index],
            };
          }
          this.shop_card_info_list.push({
            shop_id: key,
            shop_name: sitem ? sitem.shop_name : "",
            // app_type,
            card_range_list: _value,
          });
        }
      }
      let cardList = this.shop_card_info_list
        .map((shop) => {
          let list = [];
          shop.card_range_list.forEach((item) => {
            list.push({
              ...item,
              unify_discount_rate_status: !!item.unify_discount_rate_status,
            });
          });
          return [...list];
        })
        .flat();
      // console.log(cardList);
      let nlist = cards[""];
      let clist = [...cardList];
      if (nlist) {
        clist.push(nlist[0]);
      }
      this.formInfo.cardList = clist;
    },
    changeCardRange ({ item, index, list, cards, type }) {
      const { shop_id, shop_name } = item;
      const { app_type_shop_type_flag } = this.formInfo;

      let params = {
        shop_id,
        shop_name,
        // app_type,
        // shop_type,
        app_type_shop_type_flag,
        card_range_index: type == "num" ? null : index,
        card_range_list: [...list],
        change_num_card_id: item.card_id,
        trade_sn: this.formInfo.trade_sn,
      };

      API_saleCard.editRefundCardRangeOrCardNum(params)
        .then((res) => {
          if ((res.not_inactive_card_range || []).length > 0) {
            this.checkCardRange(
              [...res.not_inactive_card_range],

            ).then((type) => {
              if (type == 1) {
                this.setRangeCardList({ item, index, cards }, res);
              } else {
                this.$refs.card_list.backtrack();
              }
            });
            return;
          }
          this.setRangeCardList({ item, index, cards }, res);
        })
        .catch((err) => {
          console.error(err);
          this.$refs.card_list.backtrack();
        });
    },
    resetForm () {
      this.formInfo = {
        trade_sn: "",
        shop_id: "",
        shop_name: "",
        total_price: "",
        after_discount_price: "",
        client_name: "",
        client_addr: "",
        sell_name: "",
        link_name: "",
        link_phone: "",
        agent_name: "",
        remark: "",
        make_order_name: "",
        cardList: [],
      };
    },
    handleChangeCardList ({ list, totalPrice, discountPrice }) {
      this.cardList = list;
      this.formInfo.total_price = totalPrice;
      this.formInfo.after_discount_price = discountPrice;
    },
    handleSellerSnBlur (val) {
      if (val.target.value) {
        this.noMatchError = "";
        API_saleCard.queryTradeSellInfo(val.target.value)
          .then((res) => {
            res.client_addr = this.formInfo.client_addr || res.client_addr;
            res.link_name = this.formInfo.link_name || res.link_name;
            res.link_phone = this.formInfo.link_name || res.link_name;
            res.make_order_name =
              this.formInfo.make_order_name || res.make_order_name;
            res.remark = this.formInfo.remark || "";
            res.shop_id = "";
            res.shop_name = "";
            this.resetForm();
            // this.shop_card_info_list = res.shop_card_info_list || [];
            // let cardList = this.shop_card_info_list
            //   .map((shop) => {
            //     let list = [];
            //     shop.card_range_list.forEach((item) => {
            //       list.push({
            //         ...item,
            //       });
            //     });
            //     return [...list];
            //   })
            //   .flat();
            // cardList.length = 1
            console.log(res, this.formInfo.remark);
            this.formInfo = {
              ...this.formInfo,
              ...res,
              // cardList,
            };
            this.getShopList(this.formInfo.app_type_shop_type_flag, this.shop_card_info_list);
          })
          .catch(() => {
            let res = { ...this.formInfo };
            res.client_addr = this.formInfo.client_addr || "";
            res.link_name = this.formInfo.link_name || "";
            res.link_phone = this.formInfo.link_name || "";
            res.make_order_name = this.formInfo.make_order_name || "";
            res.remark = this.formInfo.remark || "";
            this.resetForm();
            this.formInfo = {
              ...this.formInfo,
              ...res,
            };
            this.shopList = [];
            this.shopCardList = [];
            this.$message.error("请输入正确的关联销售单号");
          });
      } else {
        let res = { ...this.formInfo };
        res.client_addr = this.formInfo.client_addr || "";
        res.link_name = this.formInfo.link_name || "";
        res.link_phone = this.formInfo.link_name || "";
        res.make_order_name = this.formInfo.make_order_name || "";
        res.remark = this.formInfo.remark || "";
        this.resetForm();
        this.formInfo = {
          ...this.formInfo,
          ...res,
        };
        this.shopList = [];
        this.shopCardList = [];
      }
    },
    handlePhoneChange (val) {
      const phoneReg =
        /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
      if (phoneReg.test(val)) {
        this.phoneError = "";
        this.saveDisabled = false;
      } else if (val && !phoneReg.test(val)) {
        this.phoneError = "手机号格式错误";
        this.saveDisabled = true;
      } else {
        this.phoneError = "";
        this.saveDisabled = false;
      }
    },
    // 获取商城的卡券列表
    getShopCardList (val) {
      API_saleCard.getShopCardList(val, this.formInfo.app_type_shop_type_flag).then((res) => {
        this.shopCardList = res.map((item) => {
          return {
            value: item.id,
            label: item.card_name,
          };
        });
      });
    },
    // 商城下拉列表change事件
    handleShopChange (val) {
      this.formInfo.shop_name = this.getShopName(val);
    },
    handleShopFocus (val) {
      if (!this.formInfo.trade_sn || !this.formInfo.app_type_shop_type_flag) {
        return this.$message.error("请输入正确的关联销售单号");
      }
      this.getShopList(this.formInfo.app_type_shop_type_flag, this.shop_card_info_list);
    },
    // 判断是否有销售开卡权限
    isPermission () {
      API_Order.getShopExtAuth().then((res) => {
        if (res.open === "OPEN") {
          this.save();
        } else {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台。"
          );
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000);
        }
      });
    },
    // 保存
    save () {
      // let cards = this.$refs.card_list.getCardData();
      // if (!this.checkCardSave(cards,true)) {
      //       return this.$message.error("请完善卡券信息");
      //     }
      this.$refs.formInfo.validate((validate) => {
        if (validate) {
          const {
            app_type_shop_type_flag,
            agent_name,
            client_addr,
            client_name,
            link_name,
            link_phone,
            sell_name,
            salesman_id,
            make_order_name,
            remark,
            after_discount_price,
            total_price,
            shop_id,
            trade_sn,
            enterprise_id,
            enterprise_name,
          } = this.formInfo;
          let sell_trade_sn = trade_sn;
          let shop_card_info_list = [];
          let data = this.$refs.card_list.getCardData();
          for (const key in data) {
            if (key == "") return this.$message.error("请完善卡券信息");

            let shop_item = {
              shop_id: key,
              shop_name: (data[key][0] && data[key][0].shop_name) || "",
              card_range_list: [],
            };
            for (let i = 0; i < data[key].length; i++) {
              const d = data[key][i];
              if (
                d.card_id == "" ||
                d.card_code_end == "" ||
                d.card_code_start == ""
              ) {
                return this.$message.error("请完善卡券信息");
              }
              const { discount_ratio, card_value, card_num } = d;
              const discount_value = this.roundNum(
                card_num * card_value * (1 - discount_ratio / 100)
              );
              const after_discount_price = this.roundNum(
                (card_num * card_value * discount_ratio) / 100
              );
              shop_item.card_range_list.push({
                ...d,
                app_type_shop_type_flag,
                discount_value,
                after_discount_price,
                unify_discount_rate: +d.unify_discount_rate,
                unify_discount_rate_status: +d.unify_discount_rate_status,
              });
            }

            shop_card_info_list.push(shop_item);
          }

          let params = {
            sell_trade_sn,
            app_type_shop_type_flag,
            agent_name,
            client_addr,
            client_name,
            link_name,
            link_phone,
            make_order_name,
            remark,
            sell_name,
            salesman_id,
            after_discount_price,
            total_price,
            shop_id,
            shop_card_info_list,
            enterprise_id,
            enterprise_name,
          };

          if (this.isEdit) {
            API_saleCard.editRefundNewRecordSell(params, this.id)
              .then((res) => {
                this.$message.success("修改成功");
                this.$router.go(-1);
              })
              .catch((err) => { });
          } else {
            API_saleCard.addRefundNewRecordSell(params)
              .then((res) => {
                this.$message.success("添加成功");
                this.$router.go(-1);
              })
              .catch((err) => { });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";
</style>
